<template>
  <div class="page">
    <div class="banner">
      <div class="container">
        <div class="banner-content">
          <div>
            <div class="banner-title">{{$i18n.t('partners.banner.title')}}</div>
            <div class="banner-title">{{$i18n.t('partners.banner.summery')}}</div>
          </div>
          <img class="banner-logo" :src="require('@/assets/image/activity/specialist/logo.png')" alt="">
        </div>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="introduction-to-online-classes">
          <el-row :gutter="20">
            <el-col :md="13">
              <div class="section-title">{{$i18n.t('partners.introduction.title')}}</div>
              <p class="section-desc">{{$i18n.t('partners.introduction.desc')}}</p>
            </el-col>
            <el-col :md="11">
              <img class="section-figure"
                   :src="require('@/assets/image/activity/specialist/introduction_to_online_classes.png')" alt="">
            </el-col>
          </el-row>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="panel-card-wrap">
          <el-row :gutter="30">
            <el-col :sm="12">
              <div class="panel-card">
                <div class="panel-card-head">
                  <div class="panel-card-title">{{$i18n.t('partners.course.title')}}</div>
                  <div class="panel-card-desc">{{$i18n.t('partners.course.value')}}</div>
                </div>
                <img class="panel-card-figure" :src="require('@/assets/image/activity/specialist/course_value.jpg')"
                     alt="">
              </div>
            </el-col>
            <el-col :sm="12">
              <div class="panel-card">
                <div class="panel-card-head">
                  <div class="panel-card-title">{{$i18n.t('partners.certification.title')}}</div>
                  <div class="panel-card-desc">{{$i18n.t('partners.certification.value')}}</div>
                </div>
                <img class="panel-card-figure"
                     :src="require('@/assets/image/activity/specialist/value_of_certification.jpg')"
                     alt="">
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>


    <section class="section">
      <div class="container">
        <div class="contents">
          <div class="section-title">Contents</div>
          <div class="contents-wrap">
            <template v-for="(item,index) in $i18n.t('partners.contents')">
              <div class="contents-item" :key="index">{{ item.text }}</div>
            </template>
          </div>
        </div>
      </div>
    </section>


    <section class="section">
      <div class="container">
        <div class="learning-guide">
          <div class="learning-guide-figure">
            <img :src="require('@/assets/image/activity/specialist/learning_guide.jpg')">
          </div>
          <div class="learning-guide-main">
            <div class="section-title">{{$i18n.t('partners.learningGuide.title')}}</div>
            <p class="learning-guide-desc">{{$i18n.t('partners.learningGuide.desc')}}</p>
            <div class="learning-guide-text">{{$i18n.t('partners.learningGuide.text')}}</div>
            <!-- <div class="learning-guide-text">
              <span>{{$i18n.t('partners.learningGuide.registerCode')}}</span>
              <span class="learning-guide-text-gray">{{$i18n.t('partners.learningGuide.registerValue')}}</span>
            </div> -->
            <div class="flex-center">
              <span class="learning-guide-btn" @click="toPage(1)">{{ $i18n.t('specialist.register') }}</span>
              <span class="learning-guide-btn" @click="toPage(2)">{{ $i18n.t('specialist.startLearning') }}</span>
              <a class="learning-guide-btn" href="https://testing.exambuilder.com" target="_blank">{{$i18n.t('partners.learningGuide.btnValue')}}</a>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
  export default {
    name: 'specialist',
    data() {
      return {
      };
    },
    methods: {
      toPage(data) {
        if (data === 1) {
          this.$router.push('/learn-register')
        }
        if (data === 2) {
          this.$router.push('/csp-learn')
        }
      }
    }
  };
</script>

<style scoped lang="less">
  .page {
    .banner {
      background-image: url("../../assets/image/activity/specialist/bg_banner.png");
      background-size: 100% auto;
      background-repeat: no-repeat;

      .banner-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 100px 0 100px;
      }

      .banner-title {
        font-size: 60px;
        line-height: 1.8;
      }

      .banner-logo {
        width: 238px;
        height: auto;
      }
    }

    .section {
      &:nth-child(odd) {
        background-color: rgba(250, 251, 252, 1);
      }
    }

    .section-title {
      color: #333333;
      font-size: 30px;
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 30px;
        background-color: rgba(199, 0, 11, 1);
        border-radius: 0 4px 4px 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 2px;
      }
    }

    .introduction-to-online-classes {
      padding: 50px 0;

      .section-figure {
        width: 100%;
        height: 360px;
        display: block;
        object-fit: cover;
        object-position: right;
        border-radius: 8px;
      }

      .section-desc {
        margin-top: 37px;
        color: rgba(102, 102, 102, 1);
        font-size: 18px;
        line-height: 39px;
        padding-left: 20px;
        margin-bottom: 20px;
      }
    }

    .panel-card-wrap {
      padding: 50px 0;

      .panel-card {
        border: 1px solid rgba(213, 221, 238, 1);
        border-radius: 8px;
        padding: 50px 26px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 700px;
        margin-bottom: 20px;
        position: relative;
      }

      .panel-card, .panel-card-head {
        &:after, &:before {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          border: 1px dashed rgb(213, 221, 238);
          border-radius: 50%;
          background-image: radial-gradient(rgba(213, 221, 238, 1) 4px, transparent 4px);
          background-position: center;
          background-size: 12px 12px;
          background-repeat: no-repeat;
        }

        &.panel-card-head:before {
          top: 14px;
          left: 14px;
        }

        &.panel-card-head:after {
          top: 14px;
          right: 14px;
        }

        &.panel-card:before {
          bottom: 14px;
          left: 14px;
        }

        &.panel-card:after {
          bottom: 14px;
          right: 14px;
        }
      }

      .panel-card-head {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .panel-card-title {
        height: 50px;
        border: 1px solid rgba(213, 221, 238, 1);
        border-radius: 50px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        font-size: 20px;
      }

      .panel-card-desc {
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        line-height: 1.8;
      }

      .panel-card-figure {
        width: 100%;
        height: 360px;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    .contents {
      padding: 70px 0;

      .contents-wrap {
        counter-reset: order 0;
        columns: 2;
        padding: 40px 0 0;
      }

      .contents-item {
        counter-increment: order 1;
        display: flex;
        align-items: center;
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        padding-right: 10px;

        &:before {
          content: counter(order);
          min-width: 22px;
          height: 22px;
          background: rgba(199, 0, 11, 1);
          border-radius: 50%;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 14px;
          font-size: 14px;
        }
      }
    }

    .learning-guide {
      margin: 40px 0;
      display: flex;
      //align-items: center;

      .learning-guide-figure {
        height: auto;
        max-height: 100%;
        border-radius: 8px 0 0 8px;
        width: 20%;
        max-width: 500px;
        flex: 1;
        position: relative;
        overflow: hidden;

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }

      .learning-guide-main {
        flex: 1;
        width: 100%;
        border: 1px solid rgba(213, 221, 238, 1);
        border-left: none;
        border-radius: 0 8px 8px 0;
        height: 100%;
        padding: 30px 30px;

        .learning-guide-desc {
          font-size: 16px;
          line-height: 1.6;
          color: rgba(102, 102, 102, 1);
          margin-top: 20px;
          margin-bottom: 30px;
        }

        .learning-guide-text {
          margin-bottom: 15px;
          color: rgba(51, 51, 51, 1);
          font-size: 18px;
        }

        .learning-guide-text-gray {
          font-weight: 600;
          color: #c7000b;
        }

        .flex-center {
          display: flex;
          justify-content: center;
        }

        .learning-guide-btn {
          min-width: 177px;
          height: 50px;
          padding: 0 20px;
          background: rgba(199, 0, 11, 1);
          border-radius: 50px;
          display: inline-block;
          line-height: 50px;
          text-align: center;
          color: #FFFFFF;
          margin: 20px auto 0;
          cursor: pointer;
          transition: .1s ease;
          user-select: none;
          position: relative;

          &:not(:last-child)::before, &:not(:last-child)::after {
            content: "";
            display: inline-block;
            position: absolute;
            height: 15px;
            width: 1px;
            background: #c7000b;
            right: -52px;
            // background: #ffffff;
          }

          &::before {
            transform: rotate(-45deg);
            transform-origin: 14px 23px;
          }
          &::after {
            transform: rotate(45deg);
            transform-origin: -32px 11px;
          }

          &:hover {
            opacity: .8;
          }

          &:active {
            opacity: 1;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .container {
      width: auto;
      max-width: 1440px;
    }
  }

  @media (max-width: 750px) {
    .page {

      .banner {
        .banner-content {
          padding: 50px 0;
        }

        .banner-title {
          font-size: (100vw / 18);
        }

        .banner-logo {
          width: 20vw;
        }
      }

      .section-title{
        font-size: 24px;
      }

      .section {
        .introduction-to-online-classes{
          padding: 30px 0;
          .section-desc{
            font-size: 16px;
            margin-top: 20px;
          }
        }

        .contents {
          .contents-wrap {
            columns: 1;
          }

          .contents-item {
            margin-bottom: 24px;
          }
        }

        .learning-guide {
          flex-direction: column;

          .learning-guide-figure {
            width: 100%;
            max-width: 100%;
            border-radius: 8px 8px 0 0;

            img {
              position: relative;
            }
          }

          .learning-guide-main {
            border-left: 1px solid rgba(213, 221, 238, 1);
            border-top: none;
            border-radius: 0 0 8px 8px;
          }
        }

      }
    }

  }


</style>
